import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  min_value as rule_min_value,
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Sua senha deve conter pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um dígito.',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const minValue = extend('min_value', rule_min_value)

// Install English and Arabic localizations.
localize({
  pt: {
    messages: {
      // required: 'Este campo de {_field_} é obrigatório.',
      email: 'O campo E-mail é inválido.',
      confirmed: 'O campo de {_field_} não é válido.',
      regex: 'O campo de {_field_} não é válido.',
      numeric: 'Use apenas caracteres numéricos.',
    },
    fields: {
      name: {
        required: 'O campo Nome é obrigatório.',
      },
      first_name: {
        required: 'O campo Nome é obrigatório.',
      },
      last_name: {
        required: 'O campo Sobrenome é obrigatório.',
      },
      email: {
        regex: 'O campo de e-mail deve ser um e-mail válido.',
        required: 'O campo E-mail é obrigatório.',
      },
      phone_number: {
        required: 'O campo Telefone é obrigatório.',
        regex: 'O formato do campo do número de telefone é inválido.',
        min: 'O campo do número de telefone deve ter pelo menos 10 caracteres.',
      },
      cell_number: {
        required: 'O campo Celular é obrigatório.',
        regex: 'O formato do campo do número de Celular é inválido.',
        min: 'O campo do número de Celular deve ter pelo menos 10 caracteres.',
      },
      cell_phone: {
        required: 'O campo Celular é obrigatório.',
        regex: 'O formato do campo do número de Celular é inválido.',
        min: 'O campo do número de Celular deve ter pelo menos 10 caracteres.',
      },
      url: {
        required: 'O campo de Url é obrigatório.',
        regex: 'O formato do campo da url  é inválido.',
      },
      zip_code: {
        required: 'O campo CEP é obrigatório.',
        regex: 'O campo CEP é inválido.',
        min: 'O campo CEP é inválido.',
      },
      password: {
        required: 'O campo de senha é necessário.',
      },
      retype_password: {
        required: 'O campo redigitar senha é obrigatório.',
        confirmed: 'A confirmação do campo de redigitação da senha não corresponde.',
      },
      corporate_name: {
        required: 'O campo Razão Social é obrigatório.',
      },
      name_fantasy: {
        required: 'O campo Nome Fantasia é obrigatório.',
      },
      cnpj: {
        required: 'O campo CNPJ é obrigatório.',
        regex: 'O campo CNPJ é inválido.',
        min: 'O campo CNPJ deve ter 14 caracteres.',
      },
      cpf: {
        required: 'O campo CPF é obrigatório.',
        regex: 'O campo CPF é inválido.',
        min: 'O campo CPF deve ter 11 caracteres.',
      },
      address: {
        required: 'O campo Endereço é obrigatório.',
      },
      addressType: {
        required: 'O campo Tipo de Logradouro é obrigatório.',
      },
      neighborhood: {
        required: 'O campo Bairro é obrigatório.',
      },
      neighborhoodType: {
        required: 'O campo Tipo de Bairro é obrigatório.',
      },
      number: {
        required: 'O campo Número é obrigatório.',
        regex: 'O campo Número é inválido.',
      },
      commission_max: {
        required: 'O campo Comissão Máxima é obrigatório.',
        regex: 'O campo  Comissão Máxima é inválido.',
      },
      commission_min: {
        required: 'O campo Comissão Mínima é obrigatório.',
        regex: 'O campo  Comissão Mínima é inválido.',
      },
      additional_margin: {
        required: 'O campo Adicional Margem é obrigatório.',
        regex: 'O campo  Adicional Margem é inválido.',
      },
      city: {
        required: 'O campo Cidade é obrigatório.',
      },
      state: {
        required: 'O campo Estado é obrigatório.',
      },
      country: {
        required: 'O campo País é obrigatório.',
      },
      complement: {
        required: 'O campo Complemento é obrigatório.',
      },
      prefix: {
        required: 'O campo Prefixo é obrigatório.',
      },
      type: {
        required: 'O campo Tipo é obrigatório.',
      },
      code: {
        required: 'O campo Código é obrigatório.',
      },
      symbol: {
        required: 'O campo Símbolo é obrigatório.',
      },
      branch: {
        required: 'O campo Agência é obrigatório.',
      },
      branch_digit: {
        required: 'O campo Dígito é obrigatório.',
      },
      account: {
        required: 'O campo Conta é obrigatório.',
      },
      account_digit: {
        required: 'O campo Dígito é obrigatório.',
      },
      owner: {
        required: 'O campo Responsável é obrigatório.',
      },
      beneficiary: {
        required: 'O campo Beneficiário é obrigatório.',
      },
      beneficiary_digit: {
        required: 'O campo Dígito é obrigatório.',
      },
      bonus: {
        required: 'O campo Bônus é obrigatório.',
      },
      comment: {
        required: 'O campo Comentário é obrigatório.',
      },
      aditional_margin: {
        required: 'O campo Margem Adicional é obrigatório.',
      },
      cost_cif: {
        required: 'O campo Custo Frete CIF é obrigatório.',
      },
      cost_fob: {
        required: 'O campo Custo Frete FOB é obrigatório.',
      },
      floating: {
        required: 'O campo Floating é obrigatório.',
      },
      color: {
        required: 'O campo Cor é obrigatório.',
        regex: 'O campo Cor é inválido.',
      },
      action: {
        required: 'O campo Ação é obrigatório.',
      },
      profile: {
        required: 'O campo Plano é obrigatório.',
      },
      trade_number: {
        required: 'O campo Nº Pregão é obrigatório.',
        regex: 'O campo Nº Pregão só aceita números.',
      },
      uasg: {
        required: 'O campo UASG/OC é obrigatório.',
        regex: 'O campo UASG/OC só aceita números.',
      },
      date: {
        required: 'O campo Data é obrigatório.',
      },
      date_capture: {
        required: 'O campo Data da Captação é obrigatório.',
      },
      date_proposal: {
        required: 'O campo Data da Proposta é obrigatório.',
      },
      date_impugnment: {
        required: 'O campo Data da Impugnação é obrigatório.',
      },
      date_clarification: {
        required: 'O campo Data do Esclarecimento é obrigatório.',
      },
      price_record_expiration_date: {
        required: 'O campo Validade do Registro é obrigatório.',
      },
      link_trade: {
        required: 'O campo Pregão é obrigatório.',
      },
      link_support: {
        required: 'O campo Effect/Conlicitação é obrigatório.',
      },
      interest: {
        required: 'O campo Interesse é obrigatório.',
      },
      priority: {
        required: 'O campo Prioridade é obrigatório.',
      },
      modality: {
        required: 'O campo Modalidade é obrigatório.',
      },
      platform: {
        required: 'O campo Site é obrigatório.',
      },
      status: {
        required: 'O campo Status é obrigatório.',
      },
      client: {
        required: 'O campo  Orgão Gerenciador é obrigatório.',
      },
      company: {
        required: 'O campo Empresa é obrigatório.',
      },
      ie: {
        required: 'O campo Inscrição Estadual é obrigatório.',
        regex: 'É necessário adicionar os caracteres especiais ( ".", "-" ou "/"  ).',
      },
      phase: {
        required: 'O campo Fase é obrigatório.',
      },
      category: {
        required: 'O campo Categoria é obrigatório.',
      },
      cost: {
        required: 'O campo Custo é obrigatório.',
      },
      freight_group: {
        required: 'O campo Região de Frete é obrigatório.',
      },
      fixed_cost: {
        required: 'O campo Custo Fixo é obrigatório.',
      },
      freight: {
        required: 'O campo Frete é obrigatório.',
      },
      freight_value: {
        required: 'O campo Valor do Frete é obrigatório.',
      },
      margin_min: {
        required: 'O campo Margem Minima é obrigatório.',
      },
      tax_aliquot: {
        required: 'O campo Aliquota de Imposto é obrigatório.',
      },
      unitary_value: {
        required: 'O campo Valor Unitário é obrigatório.',
        min_value: 'O valor unitário não pode ser menor que o preço minimo unitário.',
      },
      cost_product: {
        required: 'O campo Custo do Produto é obrigatório.',
      },
      companyStatus: {
        required: 'O campo Status é obrigatório.',
      },
      dispute: {
        required: 'O campo Disputa é obrigatório.',
      },
      freightGroup: {
        required: 'O campo Grupo de Frete é obrigatório.',
      },
      link: {
        required: 'O campo Link é obrigatório.',
      },
      login: {
        required: 'O campo Login é obrigatório.',
      },
      received_email: {
        required: 'O campo E-mail Recebido é obrigatório.',
      },
      payment_term: {
        required: 'O campo Prazo de Pagamento é obrigatório.',
      },
      warranty_term: {
        required: 'O campo Prazo de Garantia é obrigatório.',
      },
      deadline: {
        required: 'O campo Prazo de Entrega/Execução é obrigatório.',
      },
      date_emission: {
        required: 'O campo Emissão é obrigatório.',
      },
      date_validity: {
        required: 'O campo Validade é obrigatório.',
      },
      bidding: {
        required: 'O campo Órgão Gerenciador é obrigatório.',
      },
      bidding_imported: {
        required: 'O campo Licitação Importada é obrigatório.',
      },
      itemStatus: {
        required: 'O campo Status do Item é obrigatório.',
      },
      commitment_number: {
        required: 'O campo Nº Empenho é obrigatório.',
        regex: 'O formato do campo do Nº Empenho está inválido.',
      },
      date_delivery: {
        required: 'O campo Prazo de Entrega é obrigatório.',
      },
      date_expiration: {
        required: 'O campo Recebimento do Empenho é obrigatório.',
      },
      date_expiration_order: {
        required: 'O campo Validade do Pedido é obrigatório.',
      },
      date_cotation: {
        required: 'O campo Data da Cotação é obrigatório.',
      },
      negotiated_value: {
        required: 'O campo Valor Unitário Estimado é obrigatório.',
      },
      field_generic: {
        required: 'O campo é obrigatório.',
      },
      number_delivery: {
        required: 'O campo Nº da Entrega é obrigatório.',
      },
      carrier: {
        required: 'O campo Transportadora é obrigatório.',
      },
      quote_number: {
        required: 'O campo Número da Cotação é obrigatório.',
      },
      note_number: {
        required: 'O campo Nº da Nota é obrigatório.',
        regex: 'O campo Nº da Nota é inválido',
      },
      billing_date: {
        required: 'O campo Data de Faturamento é obrigatório.',
      },
      invoicing_date: {
        required: 'O campo Data de Faturamento é obrigatório.',
      },
      pay_date: {
        required: 'O campo Previsão de Pagamento é obrigatório.',
      },
      real_pay_date: {
        required: 'O campo Data de Pagamento é obrigatório.',
      },
      invoicing_delivery_date: {
        required: 'O campo Data de Entrega é obrigatório.',
      },
      expedition_date: {
        required: 'O campo Data de Expedição é obrigatório.',
      },
      annotation_invoicing: {
        required: 'O campo Anotações é obrigatório.',
      },
      driver_name: {
        required: 'O campo Nome do Motorista é obrigatório.',
      },
      documentType: {
        required: 'O campo Tipo do Documento é obrigatório.',
      },
      contractSate: {
        required: 'O campo Situação do Contrato é obrigatório.',
      },
      scope: {
        required: 'O campo Âmbito é obrigatório.',
      },
      contract: {
        required: 'O campo Contrato é obrigatório.',
      },
      contract_number: {
        required: 'O campo Nº da Ata/Contrato é obrigatório.',
        regex: 'O formato do campo do Nº da Ata/Contrato está inválido.',
      },
      contractStatus: {
        required: 'O campo Status do Contrato é obrigatório.',
      },
      date_start_validity: {
        required: 'O campo Início da Vigência é obrigatório.',
      },
      date_finish_validity: {
        required: 'O campo Fim da Vigência é obrigatório.',
      },
      difference: {
        required: 'O campo Defasagem é obrigatório.',
      },
      assistance_status: {
        required: 'O campo Status da Assistência é obrigatório.',
      },
      assistance_type: {
        required: 'O campo Tipo de Assistência é obrigatório.',
      },
      assistance_product: {
        required: 'O campo Produto da Assistência é obrigatório.',
      },
      commission: {
        required: 'O campo % do comissionamento é obrigatório.',
      },
      model: {
        required: 'O campo Modelo é obrigatório.',
      },
      lote_name: {
        required: 'O campo Nome do Lote é obrigatório.',
      },
      fob_freight: {
        required: 'O campo Frete FOB é obrigatório.',
      },
      profile_name: {
        required: 'O campo Nome do Perfil é obrigatório.',
      },
      situation: {
        required: 'O campo Situação é obrigatório.',
      },
      company_name: {
        required: 'O campo Nome da Empresa é obrigatório.',
      },
      owner_name: {
        required: 'O campo Nome do Responsável é obrigatório.',
      },
    },
  },
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
})

localize('pt')
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
